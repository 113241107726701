import React, { useState } from 'react';
import axios from 'axios';
import UserDisplayer from './userDisplayer'; // Ensure this is the correct path and name
import UserUpdater from './userUpdater'; 
import UserDeleter from './userDeleter'; 

const UserMain = () => {

    
    return (
        <>            
            
        <UserDisplayer />

        <div className="flex flex-row justify-center space-x-4 mx-auto py-4">
        <div className="flex-1 max-w-xs px-4"> {/* Adjust the max-width as per your design */}
        <UserUpdater />
        </div>
        <div className="flex-1 max-w-xs px-4"> {/* Adjust the max-width as per your design */}
        <UserDeleter />
        </div>
        </div>

        </>
    );
    }

export default UserMain; 