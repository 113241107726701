import React, { useState } from 'react';
import axios from 'axios';

const ProductCreator = () => {
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [message, setMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const product = {
        product_name: productName,
        product_price: parseInt(productPrice, 10), // Ensure product_price is an integer
      };

      const response = await axios.post(`${backendUrl}/product_create/`, product, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // If your API requires authentication
        },
      });

      setMessage("Product created successfully."); // Show success message
      console.log(response.data.message); // Log server response message
      setTimeout(() => {
        window.location.reload();
      }, 1); // Adjust the delay as needed
      
      
      setProductName('');
      setProductPrice('');
    } catch (error) {
      console.error('Error creating product:', error.response?.data?.detail || 'Unknown error');
      setMessage(error.response?.data?.detail || 'An error occurred. Please try again.');
    }
  };

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-lg">
        
        <h1 className="text-2xl font-semibold mb-6 text-center">Create Product</h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
          <input
            type="text"
            placeholder="Product Name"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
            required
          />
          <input
            type="number"
            placeholder="Product Price"
            value={productPrice}
            onChange={(e) => setProductPrice(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
            required
          />
          <button type="submit" className="px-4 py-2 bg-custom-purple text-white rounded-md hover:bg-blue-700">
            Create Product
          </button>
        </form>
        {message && (
          <div className="mt-4 text-center">
            <p>{message}</p>
          </div>
        )}
      </div>
    </>
  );
  
};

export default ProductCreator;
