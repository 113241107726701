import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_Name_DB: '',
    phone_DB: '',
    email_DB: '',
    enquiry_DB: '',
    additional_Info_DB: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';
    
    try{  
      const response = await axios.post(`${backendUrl}/contact_form`, formData); 
      if (response.data.message === "CONTACT_FORM_INSERTED") {
        alert(response.data.display);
        navigate("/"); // Redirect on success
      } else {
        alert("There is something wrong!");
      } 
    } catch (error) {
        console.error('Contact Form error', error);
        alert(error.response?.data?.response_Message || "Contact Form Failed!");
    }
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg border mt-10 mx-auto max-w-lg p-6">
      <h2 className="text-xl font-semibold text-gray-900 text-center">Contact Us</h2>
      <form onSubmit={handleSubmit} className="mt-8 space-y-6">
        <input type="hidden" name="remember" value="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="full_Name_DB" className="sr-only">Full Name</label>
            <input
              id="full_Name_DB"
              name="full_Name_DB"
              type="text"
              autoComplete="name"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Full Name"
              value={formData.full_Name_DB}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="phone_DB" className="sr-only">Phone Number</label>
            <input
              id="phone_DB"
              name="phone_DB"
              type="tel"
              autoComplete="tel"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Phone Number"
              value={formData.phone_DB}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="email_DB" className="sr-only">Email address</label>
            <input
              id="email_DB"
              name="email_DB"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Email Address"
              value={formData.email_DB}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="enquiry_DB" className="sr-only">Your Enquiry</label>
            <textarea
              id="enquiry_DB"
              name="enquiry_DB"
              rows="4"
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Your Enquiry"
              required
              value={formData.enquiry_DB}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="additional_Info_DB" className="sr-only">Additional Information</label>
            <textarea
              id="additional_Info_DB"
              name="additional_Info_DB"
              rows="2"
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Additional Information"
              value={formData.additional_Info_DB}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
