import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Navbar } from 'flowbite-react';
import myLogo from '../../images/micro.png'; // Make sure this path is correct for your logo

function NavigationBar() {
  const navigate = useNavigate();

  const isLoggedIn = !!localStorage.getItem('token'); // Check if user is logged in

  const handleAuthAction = () => {
    if (isLoggedIn) {
      localStorage.removeItem('token'); // Remove token to log out
      navigate('/'); // Redirect to home or login
    } else {
      navigate('/login'); // Redirect to login page
    }
  };

  return (
<Navbar fluid={true} rounded={true} className="navbar-custom">
      <Navbar.Brand href="/">
        <Link to="/" className="flex items-center">
          <img src={myLogo} className="mr-3 h-6 sm:h-9" alt="My Brand Logo" />
          <span className="self-center text-xl font-semibold whitespace-nowrap text-white">Growth Microservices</span>
        </Link>
      </Navbar.Brand>
      <div className="flex md:order-2 mr-4 md:mr-8 lg:mr-5"> 
        
        {/* Inserted Links next to the Login Button, added text-white and font-bold */}
        <Link to="/" className="px-4 py-2 text-sm text-white font-bold">Home</Link>
        <Link to="/FAQ" className="px-4 py-2 text-sm text-white font-bold">FAQ</Link>
        <Link to="/Microservices" className="px-4 py-2 text-sm text-white font-bold">Microservices</Link>
        <Button onClick={handleAuthAction} className="text-white font-bold">{isLoggedIn ? 'Logout' : 'Login'}</Button>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        {isLoggedIn && (
          <>
            <Navbar.Link href="/ProfileMain" className="text-white font-bold">Profile</Navbar.Link>
            <Navbar.Link href="/UserMain" className="text-white font-bold">User Management</Navbar.Link>
            <Navbar.Link href="/ProductMain" className="text-white font-bold">Product Management</Navbar.Link>
            
          </>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
