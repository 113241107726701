import React from 'react';
import { Footer } from 'flowbite-react';

function NewFooter() {
  return (

    <Footer container className="footer-custom">
      <div className="w-full mt-4 md:mt-0 md:w-auto text-center ">
        <p className="text-sm text-sm text-white font-bold">
          © {new Date().getFullYear()} Growth Microservices. All rights reserved.
        </p>
      </div>
      <div className="w-full md:w-auto text-center md:text-left">
        <Footer.LinkGroup className="flex flex-col md:flex-row gap-3 mt-3 md:mt-0 text-sm text-white font-bold">
          <Footer.Link href="/PrivacyPolicy">Privacy Policy</Footer.Link>
          <Footer.Link href="#">Licensing</Footer.Link>
          <Footer.Link href="/ContactForm">Contact</Footer.Link>
        </Footer.LinkGroup>
      </div>
    </Footer>
  );
}

export default NewFooter;
