import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserDisplayer = () => {
  const [users, setUsers] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/display_users/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="shadow-lg rounded-lg overflow-hidden mx-auto my-4 max-w-4xl"> 
      <table className="w-full table-fixed">
        <thead>
          <tr className="bg-gray-100">
            <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Full Name</th>
            <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Username</th>
            <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Email</th>
            <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">User ID</th>
            <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Phone</th>
            <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Role</th>
            <th className="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">Status</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {users.map((user) => (
            <tr key={user.user_ID_DB}>
              <td className="py-4 px-6 border-b border-gray-200">{user.full_Name_DB}</td>
              <td className="py-4 px-6 border-b border-gray-200">{user.username_DB}</td>
              <td className="py-4 px-6 border-b border-gray-200 truncate">{user.email_DB}</td>
              <td className="py-4 px-6 border-b border-gray-200">{user.user_ID_DB}</td>
              <td className="py-4 px-6 border-b border-gray-200">{user.phone_DB}</td>
              <td className="py-4 px-6 border-b border-gray-200">
                {user.role_DB.join(', ')}
              </td>
              <td className="py-4 px-6 border-b border-gray-200">
                <span className={`py-1 px-2 rounded-full text-xs text-white ${user.status_DB === 'Active' ? 'bg-green-500' : 'bg-red-500'}`}>
                  {user.status_DB}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserDisplayer;
