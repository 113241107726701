import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ItemDisplayer = () => {
  const [items, setItems] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${backendUrl}/product_display/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setItems(response.data);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);

  return (
    <div className="shadow-lg rounded-lg overflow-hidden mx-auto my-4 max-w-4xl"> 
      <table className="w-full table-fixed">
        <thead>
          <tr className="bg-gray-100">
            <th className="w-1/3 py-4 px-6 text-left text-gray-600 font-bold uppercase">Product Name</th>
            <th className="w-1/3 py-4 px-6 text-left text-gray-600 font-bold uppercase">Product ID</th> 
            <th className="w-1/3 py-4 px-6 text-left text-gray-600 font-bold uppercase">Product Price</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {items.map((item, index) => (
            <tr key={index}>
              <td className="py-4 px-6 border-b border-gray-200">{item.product_name}</td>
              <td className="py-4 px-6 border-b border-gray-200">{item.product_id}</td>
              <td className="py-4 px-6 border-b border-gray-200">${item.product_price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemDisplayer;
