import React, { useState } from 'react';
import axios from 'axios';

const ProfileUpdater = () => {
  const [field, setField] = useState('');
  const [newValue, setNewValue] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  const updateProfile = async (e) => {
    e.preventDefault();

    try {
      const payload = { [field]: newValue }; // Dynamically setting the field to update

      await axios.put(`${backendUrl}/profile_update`, payload, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      alert("Profile updated successfully."); // Show success message
      window.location.reload(); // Optionally reload to see changes or refetch profile data
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile.'); // Show error message
    }
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg border mt-5 mx-auto max-w-md">
      <div className="px-4 py-5 sm:px-6 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Update Profile</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500 mx-auto">Select a field to update its value.</p>
      </div>
      <div className="border-t border-gray-200">
        <form onSubmit={updateProfile} className="px-4 py-5 sm:p-6">
          <div className="py-3 sm:py-5 grid gap-4">
            <label className="text-sm font-medium text-gray-500 block text-center">Select Field to Update:</label>
            <select className="mx-auto mt-1 text-sm text-gray-900 form-select block w-3/4" value={field} onChange={(e) => setField(e.target.value)}>
              <option value="">Select a Field</option>
              <option value="full_Name_DB">Full Name</option>
              <option value="phone_DB">Phone</option> 
              <option value="status_DB">Status</option> 
              {/* Add more fields as needed */}
            </select>
          </div>
          <div className="py-3 sm:py-5 grid gap-4">
            <label className="text-sm font-medium text-gray-500 block text-center">New Value:</label>
            <input className="mx-auto form-input block w-3/4 mt-1 text-sm text-gray-900" type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
          </div>
          <div className="flex justify-center">
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">Update Profile</button>
          </div>
        </form>
      </div>
    </div>
  );
  
};

export default ProfileUpdater;
