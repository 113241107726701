// App.js
import React                                      from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Homepage                     from './components/Homepage/Homepage';

import NavigationBar                              from './components/Default/NavigationBar';
import NewFooter                                  from './components/Default/Footer'; // Adjust the import path according to where you've saved the Footer component
import PrivacyPolicy                              from './components/Default/PrivacyPolicy';
import ContactForm                                from './components/Homepage/ContactForm';
import FAQ                                        from './components/Default/FAQ'; 
import ServicesMain                               from './components/Microservices/ServicesMain'; 

import RegisterC0Google             from './components/reception/registerGoogle/RegisterC0Google'; 
import RegisterC1Google             from './components/reception/registerGoogle/RegisterC1Google'; 
import RegisterCXGoogle             from './components/reception/registerGoogle/RegisterCXGoogle';  



import ProfileMain        from './components/Profiles/ProfileMain'; 

import UserMain                     from './components/userManagement/userMain'; 

import ProductMain      from './components/Inventory/ProductMain'; 

import './components/css/App.css'; 
 

const App = () => {
  return (
    <Router>
      <NavigationBar />
      <div className="site-content">

      <Routes>
        <Route path="/"                             element={<Homepage />} /> 

        <Route path="/FAQ"                          element={<FAQ />} />
        <Route path="/Microservices"                element={<ServicesMain />} />


        <Route path="/login"             element={<RegisterC0Google />} /> 
        <Route path="/verify"               element={<RegisterC1Google />} /> 
        <Route path="/notification"             element={<RegisterCXGoogle />} />

        <Route path="/ProfileMain"                  element={<ProfileMain />} /> 

        <Route path="/UserMain"           element={<UserMain />} />


        <Route path="/ProductMain"           element={<ProductMain />} /> 
        
        <Route path="/PrivacyPolicy"         element={<PrivacyPolicy />} /> 
        <Route path="/ContactForm"           element={<ContactForm />} /> 
        
      </Routes>
      </div> 
      <NewFooter />
    </Router>
  );
};

export default App;


//import ProfileDisplayer from './components/Profiles/ProfileDisplayer';
//import ProfileUpdater   from './components/Profiles/ProfileUpdater';
//<Route path="/ProfileDisplayer"             element={<ProfileDisplayer />} />
//<Route path="/ProfileUpdater"               element={<ProfileUpdater />} /> 

//import UserDisplayer                from './components/userManagement/userDisplayer'; 
//import UserUpdater                  from './components/userManagement/userUpdater'; 
//import UserDeleter                  from './components/userManagement/userDeleter'; 
//<Route path="/UserDisplayer"      element={<UserDisplayer />} /> 
//<Route path="/UserUpdater"        element={<UserUpdater />} /> 
//<Route path="/UserDeleter"        element={<UserDeleter />} /> 



//import ProductDisplayer from './components/Inventory/ProductDisplayer'; 
//import ProductCreator   from './components/Inventory/ProductCreator'; 
//import ProductDelete    from './components/Inventory/ProductDelete';

//<Route path="/ProductDisplayer"      element={<ProductDisplayer />} /> 
//<Route path="/ProductCreator"        element={<ProductCreator />} /> 
//<Route path="/ProductDelete"         element={<ProductDelete />} /> 
