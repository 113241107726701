
// index.js 
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './components/css/tailwind.css'; // the path to your Tailwind CSS file


const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);
