import React, { useEffect } from 'react';
import myLogoStripe  from '../../images/stripe.webp'; // Make sure this path is correct for your logo
import myLogoHubspot from '../../images/hubspot.png'; // Make sure this path is correct for your logo
import myLogoGmail   from '../../images/gmail.webp'; // Make sure this path is correct for your logo
import myLogoVitally from '../../images/vitally.png'; // Make sure this path is correct for your logo 

const Homepage = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';


  return (
    <div id="main-wrapper" className="flex flex-col items-center justify-center ">
      <div className="text-center mt-10">
        <h1 className="text-3xl font-bold text-gray-900">Welcome</h1>
        <p className="mt-3 text font-bold text-gray-800">to the</p> 
        <h1 className="text-3xl font-bold text-gray-900 m-2">Growth Microservices</h1>
        
        
        

        <p className="mt-3 text-lg text-gray-900">This web application is built to host various microservices.</p>
        <p className="mt-3 text text-gray-900">Website enterance is only open for certain domain.</p>
        <p className="mt-3 text text-gray-900">Login: You are going to get One Time Password which will be sent to your email. </p> 
        <p className="mt-3 text text-gray-900">You can check the FAQ section.</p> 
      </div>
      
  
      <div className="mt-4 flex justify-center items-center w-full"> {/* Centered container */}
        <img src={myLogoStripe} className="h-6 sm:h-9 mx-1" alt="Stripe Logo" />
        <img src={myLogoHubspot} className="h-6 sm:h-9 mx-1" alt="Hubspot Logo" />
        <img src={myLogoGmail} className="h-9 sm:h-7 mx-1" alt="Gmail Logo" />
        <img src={myLogoVitally} className="h-9 sm:h-9 mx-3" alt="Vitally Logo" /> 
      </div>  
    </div>
  );
};

export default Homepage;


// <h1 className="text-xl font-bold text-gray-900">Version = 7 Barcode: 21</h1>
// <h1 className="text-3xl font-bold text-gray-900 mt-5"> my current backend server: {backendUrl} </h1>
// <h1 className="text-3xl font-bold text-gray-900 mt-5"> my current backend server: {backendUrl} </h1> 