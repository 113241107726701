import React, { useState } from 'react';
import axios from 'axios';

const ProductDelete = () => {
  const [productId, setProductId] = useState('');
  const [message, setMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`${backendUrl}/product_delete/`, {
        data: { product_id: productId },
  
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Ensure secure handling of the token
        },
      });
      setMessage(response.data.message);
      setProductId(''); // Optionally clear the input field
          // Reload the page after a short delay to show the message
        setTimeout(() => {
          window.location.reload();
        }, 1000); // Adjust the delay as needed


    } catch (error) {
      console.error('Error deleting user:', error.response?.data?.detail || 'Unknown error');
      setMessage(error.response?.data?.detail || 'An error occurred. Please try again.');
    }
  };

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-md">
        <h1 className="text-2xl font-semibold mb-6 text-center">Delete Product</h1>
        <form onSubmit={handleDelete} className="flex flex-col gap-4 items-center">
        <input
            type="text"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            placeholder="Product ID"
            className="mt-1 block w-3/4 px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
          />

          <button type="submit" className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700">
            Delete User
          </button>
        </form>
        {message && <div className="text-center mt-4">{message}</div>}
      </div>
    </>
  );
  
};

export default ProductDelete;
