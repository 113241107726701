import React from 'react';
import ProductDisplayer from './ProductDisplayer';
import ProductCreator from './ProductCreator';
import ProductDelete from './ProductDelete';
import ProductUpdater from './ProductUpdater';

const ProductMain = () => {
    return (
        <>
            <ProductDisplayer />
            <div className="flex flex-row justify-center space-x-4 mx-auto py-4">
            
                <div className="flex-1 max-w-xs px-4"> {/* Adjust the max-width as per your design */}
                    <ProductCreator />
                </div>
                <div className="flex-1 max-w-xs px-4"> {/* Adjust the max-width as per your design */}
                    <ProductDelete />
                </div>
                <div className="flex-1 max-w-xs px-4"> {/* Adjust the max-width as per your design */}
                    <ProductUpdater />
                </div>
            </div>
        </>
    );
}

export default ProductMain;
