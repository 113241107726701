import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; // Import useSearchParams
import axios from 'axios'; // Import Axios

function RegisterC1Google() {
    const [searchParams] = useSearchParams();
    const emailFromVerify = searchParams.get('email'); // Assuming the query parameter is named 'email'
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030'; // Default to localhost if the variable is not set

    const [email, setEmail] = useState(emailFromVerify || ''); // Pre-fill email if present in URL
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // If there's an email query param, update the email state
        if (emailFromVerify) {
            setEmail(emailFromVerify);
        }
    }, [emailFromVerify]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${backendUrl}/registerC1Verify`, {
                email: email,
                number: parseInt(otp, 10),
            });

            // Assuming the response structure has not changed
            const { data } = response;
            if (data.token) {
                localStorage.setItem('token', data.token); // Assuming JWT token is returned
                alert('Verification successful! You are now logged in.');
                navigate('/'); // Navigate to home or dashboard
            } else {
                alert('Verification failed. Please check the code and try again.');
            }
        } catch (error) {
            console.error('Error during verification:', error);
            alert('An error occurred during verification. Please try again.');
        }
    };

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg border mt-5 mx-auto max-w-md">
          <div className="px-4 py-5 sm:px-6 text-center">
            <h1 className="text-lg leading-6 font-medium text-gray-900">Email Verification</h1>
          </div>
          <div className="border-t border-gray-200">
            <form onSubmit={handleSubmit} className="px-4 py-5 sm:p-6">
              <div className="py-3 sm:py-5 grid gap-4">
                <label className="text-sm font-medium text-gray-500 block text-center">
                  Email:
                  <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                    className="mx-auto form-input block w-3/4 mt-1 text-sm text-gray-900"
                  />
                </label>
              </div>
              <div className="py-3 sm:py-5 grid gap-4">
                <label className="text-sm font-medium text-gray-500 block text-center">
                  OTP:
                  <input 
                    type="text" 
                    value={otp} 
                    onChange={(e) => setOtp(e.target.value)} 
                    required 
                    className="mx-auto form-input block w-3/4 mt-1 text-sm text-gray-900"
                  />
                </label>
              </div>
              <div className="flex justify-center">
                <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">Verify</button>
              </div>
            </form>
          </div>
        </div>
      );
      
}

export default RegisterC1Google;
