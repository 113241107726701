import React, { useState } from 'react';
import axios from 'axios';

const UserDeleter = () => {
  const [username, setUsername] = useState(''); // Renamed for clarity
  const [message, setMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`${backendUrl}/delete_user/`, {
        data: { username_DB: username }, // Adjusted to match the Pydantic model
            headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Ensure secure handling of the token
        },
      });
      setUsername(''); // Clear the input field
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error deleting user:', error);
      // Check if the detail is an object and stringify it
      const detail = error.response?.data?.detail;
      if (typeof detail === 'object') {
        setMessage(JSON.stringify(detail, null, 2));
      } else {
        setMessage(detail || 'An error occurred. Please try again.');
      }
    }
  };

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-md">
        <h1 className="text-2xl font-semibold mb-6 text-center">Delete User</h1>
        <form onSubmit={handleDelete} className="flex flex-col gap-4 items-center">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className="mt-1 block w-3/4 px-3 py-2 bg-white border border-gray-300 rounded-md"
          />
          <button type="submit" className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700">
            Delete User
          </button>
        </form>
        {message && <div className="text-center mt-4">{message}</div>}
      </div>
    </>
  );
  
};

export default UserDeleter;
