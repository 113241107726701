import React, { useState } from 'react';
import axios from 'axios';
import UserDisplayer from './userDisplayer'; // Ensure this is the correct path and name

const UserUpdater = () => {
  const [targetUsername, setTargetUsername] = useState('');
  const [targetField, setTargetField] = useState('email_DB');
  const [newValue, setNewValue] = useState('');
  const [message, setMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${backendUrl}/update_user/`, {
        username_DB: targetUsername,
        target_Field: targetField,
        new_Value: newValue,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert("User updated successfully."); // Show success message
      window.location.reload(); // Optionally reload to see changes or refetch profile data

      setMessage(response.data.message);
      // Optionally refresh user list or clear form here
    } catch (error) {
      console.error('Error updating user:', error.response?.data?.detail || 'Unknown error');
      setMessage(error.response?.data?.detail || 'An error occurred. Please try again.');
    }
  };

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-lg">
        <h1 className="text-2xl font-semibold mb-6 text-center">Update User</h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
          <input
            type="text"
            id="targetUsername"
            placeholder="Username"
            value={targetUsername}
            onChange={(e) => setTargetUsername(e.target.value)}
            className="mt-1 block w-3/4 px-3 py-2 bg-white border border-gray-300 rounded-md"
          />
          <select
            id="targetField"
            value={targetField}
            onChange={(e) => setTargetField(e.target.value)}
            className="mt-1 block w-3/4 pl-3 pr-10 py-2 text-gray-700 border-gray-300 rounded-md"
          >
            <option value="status_DB">Status</option>
            <option value="role_DB">Role</option>
            <option value="phone_DB">Phone</option>
          </select>
          <input
            type="text"
            id="newField"
            placeholder="New Value"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className="mt-1 block w-3/4 px-3 py-2 bg-white border border-gray-300 rounded-md"
          />
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700">
            Update
          </button>
        </form>
      </div>
    </>
  );
  
};

export default UserUpdater;
