import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function RegisterC0Google() {
    const navigate = useNavigate();
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';
    
    const handleCredentialResponse = async (response) => {
      console.log("Google Auth Response: ", response);
      

      const tokenBearer = `Bearer ${response.credential}`;
      try {
          // Use axios for making the API request
          await axios.post(`${backendUrl}/registerC1Consent`, {}, {
              headers: { 'Authorization': tokenBearer }
          }).then(result => {
              if (result.status === 200) {
                  navigate('/notification');
              } else {
                  console.error('Authentication failed');
                  // Optionally, handle other errors here
              }
          }).catch(error => {
              if (error.response && error.response.status === 401) {
                  // Handle 401 Unauthorized specifically
                  console.error('Unauthorized');
                  alert('Unauthorized');
              } else {
                  // Handle other errors
                  console.error('Error sending token to backend:', error);
              }
          });
      } catch (error) {
          console.error('Error during the API call:', error);
      }
    };

    return (
        <div className="flex justify-center pt-20"> 
          <div className="bg-white overflow-hidden shadow rounded-lg border max-w-sm">
            <div className="px-4 py-5 sm:px-6 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Sign In with Google</h3>
            </div>
            <div className="border-t border-gray-200 text-center py-5">
              <GoogleLogin
                onSuccess={handleCredentialResponse}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>
          </div>
        </div>
    );
}

export default RegisterC0Google;
