import React from 'react';

function RegisterCXGoogle() {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg border mt-5 mx-auto max-w-md">
      <div className="px-4 py-5 sm:px-6 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Check Your Email</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500 mx-auto">You can check your email to login.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6 text-center">
        <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox" 
           className="px-4 py-2 bg-custom-purple text-white rounded hover:bg-blue-700" 
           target="_blank" 
           rel="noopener noreferrer">
          Go to Gmail
        </a>
      </div>
    </div>
  );
}

export default RegisterCXGoogle;
