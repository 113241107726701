import React from 'react';
import ProfileDisplayer from './ProfileDisplayer'; 
import ProfileUpdater from './ProfileUpdater';


const ProfileMain = () => {

    
    return (
        <>
        <ProfileDisplayer />
        <ProfileUpdater /> 
        
        </>
    );
    }

export default ProfileMain; 