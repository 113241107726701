import React, { useState } from 'react';
import axios from 'axios';

const ProductUpdater = () => {
  const [targetProductID, settargetProductID] = useState('');
  const [targetField, setTargetField] = useState('email_DB');
  const [newValue, setNewValue] = useState('');
  const [message, setMessage] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8030';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${backendUrl}/update_product/`, {
        product_id: targetProductID,
        target_Field: targetField,
        new_Value: newValue,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert("Product updated successfully."); // Show success message
      window.location.reload(); // Optionally reload to see changes or refetch profile data

      setMessage(response.data.message);
       
    } catch (error) {
      console.error('Error updating product:', error.response?.data?.detail || 'Unknown error');
      setMessage(error.response?.data?.detail || 'An error occurred. Please try again.');
    }
  };

  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-lg">
        <h1 className="text-2xl font-semibold mb-6 text-center">Update Product</h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 items-center">
          <input
            type="text"
            id="targetProductID"
            placeholder="Product ID"
            value={targetProductID}
            onChange={(e) => settargetProductID(e.target.value)}
            className="mt-1 block w-3/4 px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
          />
          <select
            id="targetField"
            value={targetField}
            onChange={(e) => setTargetField(e.target.value)}
            className="mt-1 block w-3/4 pl-3 pr-10 bg-gray-200 py-2 text-gray-700 border-gray-300 rounded-md"
          >
            <option value="product_name">Product Name</option>
            <option value="product_price">Product Price</option>

          </select>
          <input
            type="text"
            id="newField"
            placeholder="New Value"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className="mt-1 block w-3/4 px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
          />
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700">
            Update
          </button>
        </form>
      </div>
    </>
  );
  
};

export default ProductUpdater;
